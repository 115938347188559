import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import { Col, Container, Row } from 'react-bootstrap';

const TermsPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Terms &amp; Conditions" />
      <header>
        <Container className={`pt-6 pb-4`}>
          <Row>
            <Col xs={8}>
              <h1>{data.contentfulSiteTerms.headline}</h1>
            </Col>
          </Row>
        </Container>
      </header>

      <section className={`pb-4`}>
        <Container>
          <Row>
            <Col                 
              dangerouslySetInnerHTML={{
                __html:
                  data.contentfulSiteTerms.content.childMarkdownRemark.html,
              }}>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
{
  contentfulSiteTerms {
    headline
    content {
      childMarkdownRemark {
        html
      }
    }
  }
}
`;

export default TermsPage;
